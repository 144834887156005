/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import Head from 'next/head';
import AppProvider from 'contexts/app-provider';
import { getCLS, getFID, getFCP, getLCP, getTTFB } from 'web-vitals';
import { initSentry } from 'util/sentry';
import { wakeup } from 'util/api';
import 'tippy.js/dist/tippy.css';
import 'styles/global.scss';
import 'styles/marvel.scss';
import 'styles/iphone-x.scss';
import 'styles/range-input-original.scss';

initSentry();
wakeup();

const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    function sendToGoogleAnalytics({ name, delta, id }) {
      // Assumes the global `gtag()` function exists, see:
      // https://developers.google.com/analytics/devguides/collection/gtagjs
      window.gtag('event', name, {
        event_category: 'Web Vitals',
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        event_label: id,
        // Use a non-interaction event to avoid affecting bounce rate.
        non_interaction: true,
      });
    }

    if (window.gtag) {
      getFCP(sendToGoogleAnalytics);
      getTTFB(sendToGoogleAnalytics);
      getCLS(sendToGoogleAnalytics);
      getFID(sendToGoogleAnalytics);
      getLCP(sendToGoogleAnalytics);
    }
  }, []);

  return (
    <AppProvider>
      <Head>
        <title>screenshotr | Web browser mockup creation tool</title>
      </Head>
      <Component {...pageProps} />
    </AppProvider>
  );
};

export default MyApp;
