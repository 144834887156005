import {
  ToastProvider, // Provider
  SlideShrinkToastRoot, // Toast shell (animates transitions)
  GlossyToastContent, // Inner content (icons, buttons, text, color)
} from 'react-cooked-bread';
import { AppStateProvider } from './app-state-context';
import { OptionsProvider } from './options-context';
import { OutputProvider } from './output-context';
import { UploadProvider } from './upload-context';

const AppProvider = ({ children }) => (
  <ToastProvider
    toastRoot={SlideShrinkToastRoot}
    toastContent={GlossyToastContent}
    timeout={4000}
    placement="top-right"
    autoDismiss
    pauseOnFocusLoss
  >
    <AppStateProvider>
      <OptionsProvider>
        <OutputProvider>
          <UploadProvider>{children}</UploadProvider>
        </OutputProvider>
      </OptionsProvider>
    </AppStateProvider>
  </ToastProvider>
);

export default AppProvider;
